<template>
  <div>
    <!-- Header -->
    <div class="iq-subheader">Meine Projekte</div>

    <!-- Empty state -->
    <iq-empty-state
      icon="folder"
      title="Keine Projekte gefunden"
      v-if="projects.length === 0"
    />

    <!-- Project item -->
    <div class="item" v-for="project in projects" :key="project.id">
      <!-- Avatar -->
      <div>
        <md-avatar class="md-avatar-icon">
          <md-icon class="md-primary">folder</md-icon>
        </md-avatar>
      </div>

      <!-- Info -->
      <div class="md-title">{{ project.name }}</div>
      <iq-status-pill :status="project.status" />

      <!-- Button -->
      <md-button
        class="md-icon-button margin-0"
        @click="redirect({ name: 'Project', params: { id: project.id } })"
      >
        <md-icon>chevron_right</md-icon>
      </md-button>
    </div>
  </div>
</template>

<script>
import routingMixin from "@/mixins/routingMixin";

export default {
  name: "profileProjects",
  mixins: [routingMixin],
  props: {
    projects: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/main";
@import "../../assets/css/constants";
@import "../../assets/css/mixins";

.item {
  margin-top: 1rem;
  padding: $padding;
  border: 1px solid var(--iq-gray-300);
  border-radius: $border-radius;
  background-color: white;
  display: flex;
  align-items: center;

  @include borderHover;

  & > span {
    margin-left: 1rem;
  }

  & > .md-button {
    margin-left: auto;
  }
}
</style>
