<template>
  <iq-main>
    <iq-loader :loading="loading" />

    <div class="container" v-if="!loading">
      <!-- Toolbar -->
      <iq-toolbar>
        <!-- Left -->
        <template v-slot:left>
          <!-- Avatar -->
          <div class="profile-avatar-wrapper">
            <img :src="avatarUrl" alt="profile image" />
          </div>

          <!-- Full name -->
          <div class="md-title">{{ fullName }}</div>

          <!-- Status -->
          <iq-status-pill :status="user.status" />
        </template>

        <!-- Right -->
        <template v-slot:right>
          <!-- Edit button -->
          <md-button
            class="md-primary md-icon-button margin-0"
            @click="toggleDialog"
          >
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="left">Profil bearbeiten</md-tooltip>
          </md-button>
        </template>
      </iq-toolbar>

      <!-- Content -->
      <div class="wrapper">
        <!-- Left -->
        <div class="wrapper--left">
          <profile-details :user="user" />
        </div>

        <!-- Right -->
        <div class="wrapper--right">
          <profile-projects :projects="projects" />
        </div>
      </div>
    </div>

    <!-- Dialogs -->
    <profile-update-dialog
      :is-active="isUpdateDialogActive"
      @close="handleUpdate"
      @cancel="toggleDialog"
    />

    <!-- Snackbars -->
    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--error"
      :md-active.sync="isError"
    >
      <span>{{ errorMessage }}</span>
    </md-snackbar>

    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--success"
    ></md-snackbar>
  </iq-main>
</template>

<script>
import loadingMixin from "@/mixins/loadingMixin";
import { getUser, getUserProjects } from "@/services/api/user";
import errorMixin from "@/mixins/errorMixin";
import successMixin from "@/mixins/successMixin";
import ProfileDetails from "@/components/profile/profileDetails";
import ProfileProjects from "@/components/profile/profileProjects";
import ProfileUpdateDialog from "@/components/profile/profileUpdateDialog";

export default {
  name: "Profile",
  components: { ProfileUpdateDialog, ProfileProjects, ProfileDetails },
  mixins: [loadingMixin, errorMixin, successMixin],
  data() {
    return {
      user: {},
      projects: [],
      isUpdateDialogActive: false,
    };
  },
  computed: {
    avatarUrl() {
      let seed = Math.random().toString(36);
      return `https://avatars.dicebear.com/api/bottts/${seed}.svg`;
    },
    fullName() {
      return `${this.user.first_name} ${this.user.last_name}`;
    },
  },
  created() {
    this.getUser();
  },
  methods: {
    async getUser() {
      try {
        this.user = await getUser();
        this.projects = await getUserProjects();
        this.toggleLoading();
      } catch (error) {
        console.error(error);
        this.toggleError(
          "Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal!"
        );
      }
    },
    toggleDialog() {
      this.isUpdateDialogActive = !this.isUpdateDialogActive;
    },
    handleUpdate(e) {
      this.toggleDialog();
      if (e.type === "success") {
        this.toggleSuccess(e.message);
        this.user = e.payload;
      } else {
        this.toggleError(e.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/main";

.profile-avatar-wrapper {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: $border-default;
  margin-right: 1rem;
  padding: 0.25rem;

  & > img {
    border-radius: 50%;
  }
}

.iq-pill {
  margin-left: 1rem;
}
</style>
