<template>
  <md-dialog :md-active="isActive">
    <md-dialog-title>Dein Profil bearbeiten</md-dialog-title>

    <md-dialog-content>
      <div class="md-caption">
        Bitte geben Sie an, welche Informationen Sie aktualisieren möchten.
      </div>
      <div class="md-caption">
        Sie müssen nur Felder ausfüllen, welche Sie ändern möchten.
      </div>

      <md-field>
        <label>Vorname</label>
        <md-input v-model="first_name" />
        <span class="md-helper-text"
          >Hier können Sie Ihren Vornamen ändern</span
        >
      </md-field>

      <md-field>
        <label>Nachname</label>
        <md-input v-model="last_name" />
        <span class="md-helper-text"
          >Hier können Sie Ihren Nachnamen ändern</span
        >
      </md-field>

      <md-field>
        <label>E-Mail-Adresse</label>
        <md-input v-model="email" />
        <span class="md-helper-text"
          >Hier können Sie Ihre E-Mail-Adresse ändern</span
        >
      </md-field>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button class="md-accent" @click="closeDialog('cancel')"
        >Abbrechen</md-button
      >
      <md-button class="md-primary" @click="update"
        >Profil aktualisieren</md-button
      >
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import resetMixin from "@/mixins/resetMixin";
import { updateUser } from "@/services/api/user";

export default {
  name: "profileUpdateDialog",
  mixins: [resetMixin],
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      first_name: null,
      last_name: null,
      email: null,
    };
  },
  methods: {
    closeDialog(type, e) {
      if (type === "update") this.$emit("close", e);
      if (type === "cancel") this.$emit("cancel");
      this.reset();
    },
    async update() {
      try {
        let response = await updateUser(this.$data);
        this.closeDialog("update", {
          type: "success",
          message: "Dein Profil wurde erfolgreich aktualisiert",
          payload: response,
        });
      } catch (error) {
        this.closeDialog("update", {
          type: "error",
          message:
            "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.md-dialog-content {
  width: 750px !important;
}
</style>
