<template>
  <div>
    <!-- Header -->
    <div class="iq-subheader">Profil Informationen</div>

    <!-- List -->
    <md-list>
      <md-list-item>
        <md-icon>person</md-icon>
        <span class="md-list-item-text">Name</span>
        <span>{{ fullName }}</span>
      </md-list-item>
      <md-divider class="md-inset" />

      <md-list-item>
        <md-icon>mail</md-icon>
        <span class="md-list-item-text">E-Mail-Adresse</span>
        <span>{{ user.email }}</span>
      </md-list-item>
      <md-divider class="md-inset" />

      <md-list-item>
        <md-icon>calendar_today</md-icon>
        <span class="md-list-item-text">Erstellt</span>
        <span>{{ new Date(user.created_at).toLocaleString() }}</span>
      </md-list-item>
      <md-divider class="md-inset" />

      <md-list-item>
        <md-icon>update</md-icon>
        <span class="md-list-item-text">Aktualisiert</span>
        <span>{{ new Date(user.updated_at).toLocaleString() }}</span>
      </md-list-item>
      <md-divider class="md-inset" />
    </md-list>
  </div>
</template>

<script>
export default {
  name: "profileDetails",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fullName() {
      return `${this.user.first_name} ${this.user.last_name}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.md-list {
  background-color: var(--iq-gray-50);
}
</style>
